import {ApolloLink, split} from '@apollo/client';
import {HttpLink} from '@apollo/client/link/http';
import {BatchHttpLink} from '@apollo/client/link/batch-http';
import getConfig from 'next/config';

import {SANITY_STRATEGY} from '../enums/graphql-strategies';
import {isServerSide} from '../lib/env';
import {TWO_LEGGED_AUTH_TYPE} from '../enums/auth-types';

import graphqlFetch from './graphql-fetch-service';

const getAbsoluteUrl = (url) => {
    if (isServerSide()) {
        return `http://localhost:3000${url}`;
    }

    return `${window.location.origin}${url}`;
};

const getSanityUrl = () => {
    const {publicRuntimeConfig} = getConfig();

    return publicRuntimeConfig.sanityGraphQlUrl;
};

export const getHeadersMiddleware = (ssrContext) => {
    const cookie = !ssrContext ? {} : {cookie: ssrContext.req.headers.cookie};

    return new ApolloLink((operation, forward) => {
        operation.setContext(({headers = {}}) => ({
            headers: {
                ...headers,
                ...cookie,
            },
        }));

        return forward(operation);
    });
};

export const batchKeySelector = (operation) => operation.variables.batchKey || '';

export const getBatchHttpLink = () => {
    const batchOptions = {
        batchInterval: 10,
        batchKey: batchKeySelector,
        batchMax: 50,
        credentials: 'same-origin',
        fetch: graphqlFetch,
    };

    return split(
        (operation) => operation.variables.clientStrategy === SANITY_STRATEGY,
        new HttpLink({
            uri: getSanityUrl(),
        }),
        split(
            (operation) => operation.variables.authType === TWO_LEGGED_AUTH_TYPE,
            new BatchHttpLink({
                ...batchOptions,
                uri: getAbsoluteUrl('/perks/api/graphql/two-legged'),
            }),
            new BatchHttpLink({
                ...batchOptions,
                uri: getAbsoluteUrl('/perks/api/graphql/three-legged'),
            }),
        ),
    );
};
