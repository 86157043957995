import {ApolloClient, defaultDataIdFromObject, from, InMemoryCache} from '@apollo/client';

import {isServerSide} from '../lib/env';

import {getHeadersMiddleware, getBatchHttpLink} from './graphql-client-utils';

let apolloClient;

const createClient = (initialState = {}, ssrContext = null) =>
    new ApolloClient({
        cache: new InMemoryCache({
            cacheRedirects: {
                Query: {
                    unleashFeatureToggles: (_, args, {getCacheKey}) =>
                        args.featureNames.map((featureName) =>
                            getCacheKey({
                                __typename: 'UnleashFeatureToggle',
                                featureName,
                            }),
                        ),
                },
            },
            dataIdFromObject: (object) => {
                if (object.__typename === 'UnleashFeatureToggle') {
                    return `unleashFeatureToggles:${object.featureName}`;
                }

                return object[`${object.__typename}Id`]
                    ? `${object.__typename}:${object[`${object.__typename}Id`]}`
                    : defaultDataIdFromObject(object);
            },
        }).restore(initialState),
        connectToDevTools: true,
        link: from([getHeadersMiddleware(ssrContext), getBatchHttpLink()]),
        name: 'perks-web',
        ssrMode: Boolean(ssrContext),
    });

export const graphqlClient = (initialState, ssrContext) => {
    if (isServerSide()) {
        return createClient(initialState, ssrContext);
    }

    if (!apolloClient || initialState) {
        apolloClient = createClient(initialState);

        return apolloClient;
    }

    return apolloClient;
};
